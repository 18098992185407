.ReactSelect {
  font-size: 14px !important;
  padding: 0px !important;
  .css-13cymwt-control {
    border: 2px solid #e0e0e0 !important;
    border-radius: 3px !important;
  }
  .css-t3ipsp-control {
    border: 2px solid #e0e0e0 !important;
    border-radius: 3px !important;
  }
  .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder {
    color: grey;
  }
  .css-1hb7zxy-IndicatorsContainer {
    .css-1u9des2-indicatorSeparator {
      background: none !important;
    }
    .css-1xc3v61-indicatorContainer {
      .css-tj5bde-Svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  .css-tr4s17-option {
    background-color: #f1f5f9;
    color: #7bb31a;
  }
  .css-d7l1ni-option {
    background-color: #f1f5f9;
  }
  .css-t3ipsp-control {
    border: none;
    box-shadow: none;
  }
  .css-1xc3v61-indicatorContainer:hover {
    color: hsl(0, 0%, 80%) !important;
  }
  .css-15lsz6c-indicatorContainer {
    color: hsl(0, 0%, 80%) !important;
  }
  .css-14h4o58-menu {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }
  .css-1nmdiq5-menu {
    margin-top: 2px !important;
    margin-bottom: 2px !important;
  }

  .css-1wlit7h-NoOptionsMessage {
    text-align: start !important;
  }
  .css-1p3m7a8-multiValue {
    background-color: #f5f5f5;
    border: 2px solid #e0e0e0;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
  }
  .css-12a83d4-MultiValueRemove {
    color: #df7e30;
  }
  .css-1hb7zxy-IndicatorsContainer {
    align-items: end;
  }
  .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder {
    color: #b9b9b9;
  }
}
